.mapa { 
    width: calc(100vw - 330px);
    height: 84vh;
    margin: 0 10px 10px 0;
    border-style: none;
    border-radius: 8px;
}

.boton-importar {
    padding: 10px 20px;
    background-color: #113045;
    color: white;
    border-style: solid;
    border-radius: 5px;
    border-color: #DA9E49;
    border-width: 2px;
    font-size: 1rem;
    cursor: pointer;
    outline: none;
    position: absolute;
    bottom: 25px;
    right: 20px;
    z-index: 999;
}

.boton-importar:hover {
    background-color: #DA9E49;
    color: #113045;
    border-style: solid;
    border-width: 1px;
    border-color: #113045;
    cursor: pointer;
}

/* Estilos para dispositivos pequeños (móviles) en orientación portrait */
@media only screen and (max-width: 600px) {
    .mapa { 
        min-width: 330px;
    }
}

/* Estilos para dispositivos pequeños (móviles) en orientación landscape */
@media only screen and (min-width: 601px) and (max-width: 900px) {
    
}

/* Estilos para dispositivos medianos (tabletas) */
@media only screen and (min-width: 901px) and (max-width: 1200px) {
    .mapa {
        height: 80vh;
    }
}

/* Estilos para dispositivos grandes (pantallas grandes, smart TV) */
@media only screen and (min-width: 1201px) {
    
}
