.administracion {
    width: 700px;
    min-width: 300px;
    margin: 20px;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.administracion h1 {
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
}

.filters {
    margin-bottom: 20px;
}

.filters input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
}

.table-container {
    overflow-x: auto;
}

.table-container table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
}

.table-container th,
.table-container td {
    padding: 8px;
    border: 1px solid #ddd;
}

.table-container th {
    background-color: #f0f0f0;
}

.table-container tr:nth-child(even) {
    background-color: #f9f9f9;
}

.new-record {
    display: inline-block;
    padding: 10px 15px;
    margin-top: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}

.new-record:hover {
    background-color: #0056b3;
}

.edit-icon, .delete-icon {
    width: 32px;
    height: auto;
    cursor: pointer;
}

/* Estilos para dispositivos pequeños (móviles) en orientación portrait */
@media only screen and (max-width: 600px) {
    .administracion {
        width: calc(100vw - 70px);
    }
}

/* Estilos para dispositivos pequeños (móviles) en orientación landscape */
@media only screen and (min-width: 601px) and (max-width: 900px) {

}

/* Estilos para dispositivos medianos (tabletas) */
@media only screen and (min-width: 901px) and (max-width: 1200px) {
    
}

/* Estilos para dispositivos grandes (pantallas grandes, smart TV) */
@media only screen and (min-width: 1201px) {
    
}
