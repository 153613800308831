.contenedor-filtros {
    width: 300px;
    height: 84vh;
    margin: 0 10px 10px 10px;
    border-style: none;
    border-radius: 8px;
    background-color: #e0ceb5;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-x: hidden;
    overflow-y: auto;
}

/* Estilos para dispositivos pequeños (móviles) en orientación portrait */
@media only screen and (max-width: 600px) {
    .contenedor-filtros {
        min-width: 300px;
    }
}

/* Estilos para dispositivos pequeños (móviles) en orientación landscape */
@media only screen and (min-width: 601px) and (max-width: 900px) {
    
}

/* Estilos para dispositivos medianos (tabletas) */
@media only screen and (min-width: 901px) and (max-width: 1200px) {
    .contenedor-filtros {
        height: 80vh;
    }
}

/* Estilos para dispositivos grandes (pantallas grandes, smart TV) */
@media only screen and (min-width: 1201px) {
    
}
