.cabecera {
    width: calc(100vw - 330px);
    height: 120px;    
    margin: 10px 10px 10px 10px;
    border-style: none;
    border-radius: 8px;
    background-color: #e0ceb5;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.titulo {
    width: 95%;
}

.icono-acceso {
    position: relative;
    width: auto;
    height: 90px;
    right: 15px;
    cursor: pointer;
}

/* Estilos para dispositivos pequeños (móviles) en orientación portrait */
@media only screen and (max-width: 600px) {
    .cabecera {
        width: calc(100vw - 20px);
    }

    .icono-acceso {
        height: 70px;
    }
}

/* Estilos para dispositivos pequeños (móviles) en orientación landscape */
@media only screen and (min-width: 601px) and (max-width: 900px) {
    .icono-acceso {
        height: 70px;
    }
}

/* Estilos para dispositivos medianos (tabletas) */
@media only screen and (min-width: 901px) and (max-width: 1200px) {
    
}

/* Estilos para dispositivos grandes (pantallas grandes, smart TV) */
@media only screen and (min-width: 1201px) {
    
}
