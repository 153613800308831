.filtros-ecorregiones {
    width: 280px;
    margin: 0 10px 10px 10px;
    border-style: none;
    border-radius: 8px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.filtros-ecorregiones-visible {
    display: block;
    transition: all 0.3s ease;
}

.filtros-ecorregiones-oculto {
    display: none;
    transition: all 0.3s ease;
}
