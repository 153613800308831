.formulario-creacion-edicion-container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente */
    z-index: 1000;
}

.formulario-creacion-edicion {
    width: 300px; /* Ancho fijo para el formulario */
    font-family:"Segoe UI";
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.formulario-creacion-edicion label {
    display: block;
    margin-bottom: 5px;
    color: #333;
    font-weight: bold;
}

.formulario-creacion-edicion input[type="text"],
.formulario-creacion-edicion input[type="file"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
}

.formulario-creacion-edicion input[type="checkbox"] {
    margin-right: 5px;
}

.formulario-creacion-edicion button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.formulario-creacion-edicion button:hover {
    background-color: #0056b3;
}

.cabecera-formulario-creacion-edicion p {
    position: relative;
    width: 30px;
    height: 30px;
    font-size: 120%;
    text-align: center;
    color: #113045;
    background-color: #fff;
    border-style: solid;
    border-color: #113045;
    border-width: 1px;
    border-radius: 50%;
    cursor: pointer;
}

.cabecera-formulario-creacion-edicion {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
