.logo {
    width: 280px;
    height: auto;
    margin: 10px 20px 10px 20px;
}

/* Estilos para dispositivos pequeños (móviles) en orientación portrait */
@media only screen and (max-width: 600px) {
    .logo {
        width: 200px;
    }
}

/* Estilos para dispositivos pequeños (móviles) en orientación landscape */
@media only screen and (min-width: 601px) and (max-width: 900px) {

}

/* Estilos para dispositivos medianos (tabletas) */
@media only screen and (min-width: 901px) and (max-width: 1200px) {
    
}

/* Estilos para dispositivos grandes (pantallas grandes, smart TV) */
@media only screen and (min-width: 1201px) {
    
}
