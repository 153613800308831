@font-face {
    font-family:"Segoe UI";
    src: url("recursos/fuentes/segoe-ui-light.ttf");
}

body{
    background-color:#113045;
    overflow-x:hidden;
    overflow-y:hidden;
}

.App {
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}

h1 {
    font-size: 2em;
    font-family:"Segoe UI";
    color: #000;
    text-align: center;
    margin: 0.5em;
}

h2 {
    font-size: 1.5em;
    font-family:"Segoe UI";
    color: #000;
    text-align: left;
    margin-top: 0.75em;
    margin-left: 15px;
    margin-bottom: 0.75em;
    cursor: pointer;
}

h2:hover {
    color: #005084;
}

hr {
    width: 250px;
    height: 1px;
    margin: 0;
    margin-left: calc((100% - 250px) / 2);
    border: none;
    background-color: #333;
}

select {
    width: 90%;
    padding: 4px;
    margin-bottom: 5px;
    margin-left: 5%;
    border-style: solid;
    border-color: #005084;
    border-radius: 8px;
    background-color: #fff;
    font-size: 1rem;
}

label {
    display: block;
    cursor: pointer;
    font-size: 1rem;
    color: #333;
}

input[type="checkbox"] {
    margin: 0 10px 0 20px;
    cursor: pointer;
    transform: scale(1.5);
    accent-color: #007bff;
}

input[type="checkbox"]:checked {
    background-color: #007bff;
    border-color: #0056b3;
}

input[type="checkbox"]:hover {
    cursor: pointer;
}

/* Estilizar la barra de desplazamiento para todos los elementos y la ventana del navegador */
::-webkit-scrollbar {
    width: 10px; /* Ancho del scrollbar */
}

/* Estilizar la pista (track) del scrollbar */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Estilizar el pulgar (thumb) del scrollbar */
::-webkit-scrollbar-thumb {
    background: #DA9E49;
    border-radius: 5px;
}

/* Estilizar el pulgar del scrollbar al pasar el mouse por encima */
::-webkit-scrollbar-thumb:hover {
    background: #b58900;
}

/* Para Firefox */
html {
    scrollbar-width: thin;
    scrollbar-color: #DA9E49 #f1f1f1;
}

.contenedor-horizontal-1, .contenedor-horizontal-2 {
    display: flex;
    flex-direction: row;
}

/* Estilos para dispositivos pequeños (móviles) en orientación portrait */
@media only screen and (max-width: 600px) {
    .App {
        overflow: hidden;
    }

    h1 {
        font-size: 1.5em;
    }

    .contenedor-horizontal-1 {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .contenedor-horizontal-2 {
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        overflow: auto;
    }
}

/* Estilos para dispositivos pequeños (móviles) en orientación landscape */
@media only screen and (min-width: 601px) and (max-width: 900px) {
    .App {
        overflow-x: hidden;
        overflow-y: auto;
    }

    h1 {
        font-size: 1.5em;
    }
}

/* Estilos para dispositivos medianos (tabletas) */
@media only screen and (min-width: 901px) and (max-width: 1200px) {
    
}

/* Estilos para dispositivos grandes (pantallas grandes, smart TV) */
@media only screen and (min-width: 1201px) {
    
}
