.contenedor-administracion {
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1001;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.cabecera-administracion {
    width: 700px;
    margin-top: 20px;
    min-width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-radius: 8px;
    z-index: 1;
}

.cabecera-administracion p {
    position: relative;
    width: 30px;
    height: 30px;
    right: 10px;
    font-size: 120%;
    text-align: center;
    color: #113045;
    background-color: #fff;
    border-style: solid;
    border-color: #113045;
    border-width: 1px;
    border-radius: 50%;
    cursor: pointer;
}

/* Estilos para dispositivos pequeños (móviles) en orientación portrait */
@media only screen and (max-width: 600px) {
    .cabecera-administracion {
        width: calc(100vw - 30px);
    }
}

/* Estilos para dispositivos pequeños (móviles) en orientación landscape */
@media only screen and (min-width: 601px) and (max-width: 900px) {

}

/* Estilos para dispositivos medianos (tabletas) */
@media only screen and (min-width: 901px) and (max-width: 1200px) {
    
}

/* Estilos para dispositivos grandes (pantallas grandes, smart TV) */
@media only screen and (min-width: 1201px) {
    
}
