.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Asegúrate de que esto sea mayor que el resto de los elementos */
}

.loader-container {
    text-align: center;
}

.loader-spinner {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #113045;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
