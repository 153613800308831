/* Estilos para el formulario de inicio de sesión */
.login-container {
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente */
    z-index: 1000;
}

.login-form {
    width: 300px; /* Ancho fijo para el formulario */
    font-family:"Segoe UI";
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.login-form div {
    margin-bottom: 15px;
}

.login-form label {
    display: block;
    margin-bottom: 5px;
}

.login-form input[type="text"],
.login-form input[type="password"] {
    width: 93%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.login-form button {
    width: 100%;
    padding: 10px;
    font-family:"Segoe UI";
    background-color: #113045;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.login-form button:hover {
    background-color: #0056b3;
}

.login-form p {
    position: relative;
    width: 30px;
    height: 30px;
    font-size: 120%;
    text-align: center;
    color: #113045;
    background-color: #fff;
    border-style: solid;
    border-color: #113045;
    border-width: 1px;
    border-radius: 50%;
    cursor: pointer;
}

.cabecera-login {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}